$(document).ready(function () {
  $('.js-burger').on('click', function(){
    if($('.mobile-nav').hasClass('collapse')) {
      $('.mobile-nav').removeClass('collapse');
      $('.mobile-nav').attr('aria-hidden', 'false');
    }
    else {
      $('.mobile-nav').addClass('collapse');
      $('.mobile-nav').attr('aria-hidden', 'true');
    }
  });
});
